const devURL = "http://localhost:83";
const prodURL = "https://yddrc.com";
const requestURL = process.env.NODE_ENV === 'development' ? devURL : prodURL;

const config = {
  //配置默认语言
  Language: "vn",
  //Language: "cn",
  // Language :'tc',
  //ApiUrl: "https://yddrc.com",
  ApiUrl: requestURL,
};
export default config;
