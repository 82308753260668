export default {
  mixRecharge: ["Please fill in your email address"],
  language: "Language",
  common: ["Online service", "Unhover","Confirm","Cancel"],
  upload: ["Uploading...", "Wrong format", "Uploaded successfully", "Upload failed"],
  vanPull: ["", "No data"],
  login: {
    text: ["Language","Mlink","Sign In"],
    label: ["Logging in...", "Sign In immediately"],
    placeholder: ["Please input your email", "Please enter your password"],
    default: ["No account?", "Register Now","come up against difficulties Contact customer service","Forgot Your Password"],
    codes:["account not exist","wrong password","account freezed","fail"],
      text2: ['Mail', 'Verifica', 'Password', 'Invite', 'Phone'],
      text1: ['Log in', 'Welcome to EMC', "Email or phone", 'Password','Register'],
  },
  register: {
    text: [
      "Mlink",
      "Verification code is being sent...",
      "Obtain verification code",
      "Registering...",
      "Register Now",
    ],
    placeholder: [
      "Please input your email",
      "Please enter verification code",
      "Please enter your password",
      "Please confirm your password",
      "Please enter the invitation code",
      "Different passwords twice",
      "Please enter verification code",
      "Invalid email"
    ],
    label: ["Already have account?{a} {line}",
        "Return to login",
        "come up against difficulties Contact customer service"],
    codes:["Account exist","empty code","wrong code","password not the same","wrong recommend","fail"]
  },
  resetpwd:["Forgot password","Please input your email","Please enter verification code","Obtain verification code","Please enter password","Reset Password"],
  footer: ["Front page", "Hall", "Order", "Mine"],
  home: {
      WebMarket:'WebMarket',
      special_attention:'Special attention',
      spot_market:'Market',
    label:["Welcome"],
    broadcast: "Congratulations Member{member} Become{grade}",
    menu: ["Funding Journal", "Tutorial", "Invitation", "Customer Service"],
    noticeTitle: "Latest Announcement",
    msg: "Mission not open",
    video: "No video tutorials yet",
      showmore: "show more",
      contents: ['C2C Trading',
          'This is the best two line introduction copy!',
          'Quick transactions',
          'This is the best two line introduction copy!',
          'Is a cryptocurrency exchange suitable for everyone?',
          'Client',
          'Supported countries',
          'Quarterly transaction volume',
          '4 steps to complete ',
          'cryptocurrency purchase',
          'Create your free Exchange account',
          'adequate security',
          'Connect your financing options',
          'Buy and sell 200+ cryptocurrencies',
          'More',
          'MASSIVE TRADING CENTER',
          'The bustling trading market is safe,reliable, and confidential. We look forvard to your participation',
          'There are also mysterious welfare orders with a return rate of up to 20%',
          'Enter the trading center',
          'Our commitments and ',
          'services',
          'Safety first, customer foremost. In order to ensure ',
          'the safety of your funds, we will continue to work hard',
          'Reserve Description',
          'We promise that the platform holds all user',
          'assets at a reserve ratio of at least 1:1.',
          'Safe Cold Storage',
          'We securely store most of our digital assets',
          'in offline multi-signature wallets.',
          'User Protection Fund',
          'We have a user protection fund worth 300',
          'million USDT, providing an extra layer of',
          'protection for potential security risks.',
          'Technical security,',
          'stability, and reliability',
          'We have a top notch technical team that focuseson all aspects Security protection, independentlydeveloped efficient engine, ensuring The systernstill operates reliably under massive orders.',
          'Customer first ',
          'philosophy',
          'Targeting the global market, with multilingual support. 7 * 24 hours Online customer service, professional customer service.',
          'Global Operations',
          'International operations team, with years of experience in blockchain and finance Business experience, global market expansion to over 50 regions',
          'Rich activity rewards',
          'Exciting operation activities, high rewards, and soft hands',
          'Common Questions',
          'We have focused on cryptocurrency for more than ten years and only provide safe, stable and profitable services. We have more than 20 top wealth management stewards. The logic that changes rapidly over time should not be regarded as simply creating or testing the underlying layer. A standard system that takes a new leap forward through token brokerage transactions.',
          'After becoming a member of the BITCOIN platform, you can purchase cryptocurrencies through major exchanges and sell them on the BITCOIN platform. The unit price sold on the BITCOIN platform will be higher than the 3%-10% profit point sold on the exchange. For example, you can buy 1000USDT through any exchange such as upbit, Coinone, coinbase, etc., and sell it to people in banned countries through the BITCOIN platform, earning 3-10% profit. This means you can earn 30-100USDT.',
          'Who are we?',
          'What ‘s the platform membership income model? ',
      ]
  },
  hall:{
    default:[
        "Trading Floor","Balance","I want to buy","I want to sell","Confirm sell",
      "Order List","Sell Order","Sell to merchant","Pending Order","Buy","Sell"
    ],
    list:[
        'Quantity','Unit Price','Not traded','Sell USDT','Please enter the amount of USDT',
        'Get amount',"Amount",'Buy USDT','Amount spent','Coundown'
    ],
    kbip:["Revoke","Excess amount","Must be within the amount"],
      btn:['sell','documentary']
  },
  msglist:[
    "Message list"
  ],
  sell:{
      placeholder:["Please enter the selling price","Please enter the quantity to sell"],
      label:["balance(USDT)","Name","Bank Account","Current price(USDT)","best price to sell","selling price","sold quantity","best price","all","sure to sell"]
  },
  buy:{
    placeholder:["Please enter a buy price","Please enter the purchase quantity","Minimum amount","maximum amount"],
    label:["balance(USDT)","Name","Bank Account","Current price(USDT)","buy best price","buy price","Buy quantity","best price","all","sure buy","Amount"]
  },
  user: {
    default: [
      "Personal center",
      "Login account",
      "Invitation code",
      "Sign Out",
      "Balance",
      "Gold",
      "Wallet",
        "Set Up",
        "Asset Log",
    ],
    menu: [
      "My assets",
      "Team list",
      "Account binding",
      "Account details",
      "Platform Introduction",
      "Sign out",
    ],
  },
 bindAccount: {
    default: ["Bind Account","Submit"],
    fields: ["Bank Name","Account Name", "Bank Account", "Cellphone number","name","Types","USDT Address"],
    placeholder: ["Please enter bank name","Please enter account name","Please enter Bank Account","Please enter phone number","Please enter bank name",
      "Please select USDT type","Please enter USDT address"],
     fields2: ["Account", "Phone", "Card No","Bank Name","Type","Address"],
     placeholder2: ["Please enter account","Please enter phone","Please enter card no",
         "Please enter bank name",
         "Please choose USDT type","Please enter USDT address"],
  },
  wallet: {
    default: [
      "My Assets",
      "Recharge",
      "Withdraw",
      "Recharge record",
      "Withdrawals record",
      "Recharged amount",
      "Amount sold",
      "Earned",
      "Account balance",
      "Balance",
      "Recharge amount(USDT)",
      "Amount sold(USDT)",
      "Total revenue",
        "Upgrade Merchant",
      "To upgrade the merchant, please contact customer service",
        "Confirm the settled business",
        "Merchant Certification",
      "You need pay","Payment voucher","Merchant deposit","Authenticate now","Nnformation","You need pay","Whether the balance is insufficient to recharge",
        "Withdrawal Amount",
        "Merchant Account",
        "Royal Merchant Account"
    ],
    label: [
      "Withdrawal method",
      "Withdrawal Amount",
      "Fund password",
      "Submit",
      "Phone number",
      "Mail",
      "IFSC",
      "Sure",
        "Extract U",
        "Extract ",
    ],
    placeholder: [
      "Choose a withdrawal method",
      "Please enter the withdrawal amount",
      "Please enter the fund password",
      "Please select a withdrawal method",
      "Please enter the recipient's mobile number",
      "Please enter recipient email",
      "Please enter payee IFSC",
    ],
    msg: [
      "You have not set up a fund password, please set it first",
      "You have not bound your PIX account, please bind it first",
      "Sure you want to withdraw?",
    ],
  },
  recharge: {
    default: [
      "Wallet recharge",
      "Wallet pre-charge",
      "Recharge method",
        "Recharge",
        "Confirm",
    ],
    label: [
      "Category",
      "Address",
      "Recharge amount",
      "Enter amount",
      "Upload certificate",
        "Copy successfully",
        "Precharge Exchange",
        "RechargeOrder"
    ],
    placeholder: [
      "Types",
      "Address",
    ],
    info: [
      "Recharge amount",
      "Order number",
      "Beneficiary Bank",
      "Receiving account",
      "Payee",
      "Copy",
    ]
  },
  task: {
    tabs: ["All","In progress", "Expired", "Completed","Wait pay","Wait confirm"],
    default: ["Order List",],
    msg: ["Submit review", "Submitted, please wait for review", "Failed to submit, please resubmit", "Paid"],
  },
  userInfo: {
    default: [
      "Set up",
      "Modify avatar",
      "Account Number",
      "Mail",
      "Credit",
      "Details",
      "Change Password",
      "Fund password",
      "Click on settings",
      "Modify avatar",
      "Modify login password",
      "Modify the fund password",
      "Submit",
      "Empty the cache",
      "View information",
      "Nick name",
      "Recommendation code:",
        "Please upload a picture to modify the avatar",
    ],
    label: [
      "Original login password",
      "New login password",
      "Confirm password",
      "Original fund password",
      "New fund password",
      "Confirm password",
      "Save"
    ],
    placeholder: [
      "Please enter the original login password",
      "Please enter a new login password",
      "Please confirm the login password",
      "Please enter the original fund password",
      "Please enter new fund password",
      "Please confirm the fund password",
    ],
  },
  fundRecord: {
    default: ["Expense record", "Recharge record", "Asset log", "Charge", "Receive", "Branch"],
    tabs: ["Income", "Expenditure", "Recharge"],
  },
 dialog: [
    "Hint",
    "Confirm",
    "Submitting...",
    "Copy successfully",
    "IOS system version is not supported",
    "Registering...",
    "Loading...",
     "Loading...",
  ],
  serviceCenter: [
    "Customer Service",
    "Hi,I am a dedicated customer service~",
    "Glad to serve you",
    "Self service",
    "Online service",
    "Recharge customer service",
    "Line customer service",
  ],
 userTaskRecord: [
    "My Order",
    "Sell order",
    "Buy order",
    "Current state",
    "Get commission",
    "Completed",
  ],
  withdrawlist: [
    "Withdrawals Record",
  ],
  teamReport: {
    default: [
      "Team list",
    ],
  },
  common2:['Beginner\'s Tutorial',"Contact Customer Service","Terms of Service, see details","Customer service 1","Customer service 2"],
  common3:['Success',"Platform Introduction"],
  invite:['Share promotion','My invitation code',"Copy the invitation code to invite more friends to join","copy"],
  common4:['Real name authentication',"Submitted successfully","matters needing attention",
      "Content of matters","premium received","Tax amount","Financial certificate"
      ,"Please finish card binding","Please finish ID auth","Please finish business auth"],
  common5:["Sold","Minutes","before","Save QR code","Open","The account is frozen and cannot be traded for the time being","APP"],
  common6:["RechargeOrder","OrderNumber","Amount","Time","Status"],
  hall2:["min sell amount is","","exceed daily sell count","My order","Order List","Pending orders","buy","sell"],
  register2:["Email Registration","Mobile registration","Please enter phone number"],
  withdraw2:["Withdraw","SellerAccount","Balance","Need pay","Pay","There are still tasks that have not been completed. Cash withdrawal is not allowed for the time being","Withdrawal count exceed"],
  mytask2:["MerchantAccount","price","amount","balance","pay amount","match time","OrderDetail","number can't be 0","balance not enough","price can't 0","wrong scope"],
  taskOrder2:["You still have an open order and can't be cancelled","balance not enough","Confirm","fail","You still need to complete the order before you can withdraw cash:"],
  busAuth2:['MerchantUp','I agree',"up confirm","up Merchant",
      "If you need to modify the bank card information, please contact customer service",
  "The current account needs to be upgraded to a merchant account"],
    recharge2:['amount required','screenshots required'],
    buy3:["count down","hour","type",'WelfareOrder','GroupOrder','count limit','count limit','specify Userid','input user id','PlatformOrders'],
    hall3:["day","hour","minute","second","GroupOrder","account",'overtime'],
    sell3:['sell price can not bigger than best price'],
    busAuth3:["Member","Merchant","CrownMerchant"],
    recharge3:["Cal"],
    home3:["Miner","Guess","Blind box","Financial","Coming soon"],
    home4:["Quick trade","Quick buy USDT","C2C trade","TransferUSDT",'Online',"24H Amount(USDT)"],
    common7:["Contact service if have any questions","Contact service","Ask Help","Setting","All","Invite","MemberCount","Month New","Reg time","下属人数"],
    hall4:['Type','Member',"Your account","trade success","complete successfly","TotalAmount","Buy","Sell"],
    task3:["All","In Progress","Expired","Completed"],
    my:["Are sure to logout?"],
    bindAccount2:["Confirm that the information is filled in correctly, otherwise the normal transaction will be affected",
        "Bind now","Customer Service"],
    recharge4:["For the safety of your funds, after the transfer is successful, please submit the screenshot of successful transfer for review!"],
    resetpwd2:['Email Registration','Mobile Registration','Note: the password is composed of 6 ~ 16 characters and is not case sensitive'],
    home2:["Special Attension","Money market"],
    login1:["Next","Login now","Looks like you dont't have an account. Let's create a new account:","Agree and continue"],
    login2:["More login ways","Continue with ","Facebook","Google","Apple"],
    service2:["Trading","Other"],
    buy2:["Order type","Buy successfull","USDT was bought successfully"],
    sell4:['You confirm this sale',"Verify","Successful sale","You successfully sell","Wait for the buyer to pay","Complete"],
    hall5:["Member"],
    busauth:['Identify information','Please input your real name',
        'Please input your ID number','upload photo',
        'please upload your ID photo',"Please upload bank detail"],
    recharge5:["Please upload a photo","Recharge successfully",
        "USDT was successfully recharged to the account","Verify"],
    note:["Note","tipsssss","Telephone Number","Please input your telephone number","Leave a message","Please leave your message here",
        "Upload pictures","Please upload picture","Submit successfully","We will contact you as soon as possible","Close"],
    notelist:[
        "Note List"
    ],
    search:['Search','no data found',"Please input keyword to find"],
    searchs:{
        orders:"Order",recharge:"Recharge",withdraw:"Withdraw"
    },
    team2:["Invatation details","Invite friends"],
    task4:["My Task"],
    lang3:["English","Korean","Taiwanese"],
    login3:["Account or password error, please re-enter"],
    shiming:['Tax Audit'],
    sidebar:['Home',
        'Trading Hall',
        'My assets',
        'Account binding',
        'Asset Log',
        'Team List',
        'Invitation to Register',
        'App Download',
        'Language',
        'Dark mode',
        'Platform Introduction',
        'Sign out'],
    appDown: {
      default: [
          'Download App',
          'Android Download',
          'Apple Download',
      ]
    },
    memberRrading: {
        default: [
            'Transactions between friends',
            "Transaction time",
            "Transaction type",
            "The friend has no transaction data",
            'Transaction amount'
        ]
    },
    help: {
        default: [
            'Why we established BITCOIN?',
            'According to reports, countries and regions that have announced absolute bans include Algeria, Bangladesh, China, Egypt, Iraq, Morocco, Nepal, Qatar and Tunisia. However, for this huge market, major exchanges around the world still cannot let go of these markets, so the third-party brokerage platform BITCOIN was born.\n',
            'BITCOIN third-party brokerage platform backend:',
            'BITCOIN three-party brokerage platform, which was created with the approval of major exchanges such as coinbase, binance, crypto, etc.',
            'Third-party brokerage platform profit model:',
            'In countries where logging in or registering for the integration of the BITCOIN third-party brokerage platform is prohibited, cryptocurrencies cannot be purchased through their own exchanges. In countries where the integration of the BITCOIN third-party brokerage platform is prohibited, people who need to purchase cryptocurrencies mainly register for the BITCOIN third-party brokerage platform. Become a commercial merchant user, place orders to purchase cryptocurrencies through the BITCOIN platform, and be charged fees for each transaction based on the demand and quantity of the order.',
            'Platform membership income model',
            'After becoming a member of the BITCOIN platform, you can purchase cryptocurrencies through major exchanges and sell them on the BITCOIN platform. The unit price sold on the BITCOIN platform will be higher than the 3%-10% profit point sold on the exchange. For example, you can buy 1000USDT through any exchange such as upbit, Coinone, coinbase, etc., and sell it to people in banned countries through the BITCOIN platform, earning 3-10% profit. This means you can earn 30-100USDT.',
            'Mode description',
            'Mode 1: The platform automatically matches sales orders and matches the order amount and profit ratio based on the members VIP level. and the number of sales orders per day.',
            'For example:',
            'VIP1 can automatically match sales orders every day and earn 3% of sales as order income.',
            'VIP2 can automatically match and sell 2 orders per day and earn 5% of sales through these orders.',
            'VIP3 can automatically match selling orders 3 times a day, and you can get 7% of the selling amount as selling profit.',
            'VIP4 can automatically match selling orders 4 times a day, and can sell 9% of the selling amount.',
            'VIP5 can automatically match and sell 5 orders per day and earn 10% of sales from these orders.',
            'Mode 2: Group buying',
            'The amount of cryptocurrency purchased by global franchisees in BITCOIN in the lobby every day is different, and the profit points of each franchisee are also different. Some franchisees give very high profit points for emergency purchases. When some merchants are eager to acquire, the profit point is relatively higher than the automatically matched sales revenue. The point is that there is no limit on group selling orders and you can do it every day. As long as there are sellers who can buy, platform members can sell.',
            'Mode 3: Arbitrage',
            'Arbitrage trading is where global investors cooperate with our artificial intelligence system to provide arbitrage trading services between global currencies.',
            'It is a program that introduces artificial intelligence AI to automatically match the trading systems of investors in various regions, and can make profits at any time. An automatic trading program, this is a program that uses Martin\'s trading technology to trade to make profits.',
            'Company Profile',
            'We have focused on cryptocurrency for more than ten years and only provide safe, stable and profitable services. We have more than 20 top wealth management stewards. The logic that changes rapidly over time should not be regarded as simply creating or testing the underlying layer. A standard system that takes a new leap forward through token brokerage transactions.',
        ]
    },
};
